import { get } from 'lodash';
import {
  SECTION_CATEGORY,
  getAppConfig,
  getCategoryBySlug,
  getCategoryByTranslationSlug,
} from '@wix/communities-blog-client-common';
import {
  fetchCategoryPostsBySlug,
  completeFetchCategoryPosts,
} from '../../../common/actions/fetch-category-posts';
import { navigateWithinBlog } from '../../../common/actions/navigate-within-blog';
import { pageOpened } from '../../../common/actions/page-opened';
import { isExcludePostContentSupported } from '../../../common/selectors/layout-selectors';
import { getQueryLocale } from '../../../common/selectors/locale-selectors';
import {
  getIsValidPage,
  getShowPagination,
  getPageSize as getPageSizeFromSettings,
} from '../../../common/selectors/pagination-selectors';
import { CATEGORY_PAGE } from '../../../common/services/detect-route';
import { generateCategorySEOTags } from '../../../common/services/generate-seo-tags/generate-category-seo-tags';
import {
  encodeURISlug,
  resolveCategorySlug,
} from '../../../common/services/slug';
import { SET_APP_SETTINGS } from '../../../common/store/app-settings/app-settings-actions';
import { SET_BASIC_PARAMS } from '../../../common/store/basic-params/basic-params-actions';
import {
  isSSR,
  isSite,
} from '../../../common/store/basic-params/basic-params-selectors';
import { FETCH_CATEGORIES_SUCCESS } from '../../../common/store/categories/fetch-categories';
import { fetchProfileUrls } from '../../../common/store/profile-urls/profile-urls-actions';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import { waitForAction } from '../../../common/store/wait-for-action';
import { ROUTE_404 } from '../../constants/routes';

export const createCategoryPageRouter =
  (store, wixCodeApi) =>
  async ({ params }, redirect, { preFetch, preFetchResult }) => {
    const page = parseInt(get(params, 'page', '1'), 10);
    const slug = resolveCategorySlug(params);
    const shouldExcludeContent = () =>
      isExcludePostContentSupported(store.getState(), SECTION_CATEGORY);

    if (preFetch) {
      return store.dispatch(
        waitForAction(
          [SET_APP_SETTINGS, SET_BASIC_PARAMS, FETCH_CATEGORIES_SUCCESS],
          () => {
            const category = getCategoryBySlug(store.getState(), slug);
            const lang = getQueryLocale(store.getState());

            if (lang && !category) {
              const translatedCategory = getCategoryByTranslationSlug(
                store.getState(),
                slug,
                lang,
              );

              if (translatedCategory) {
                return navigateWithinBlog(
                  `/categories/${translatedCategory.slug}`,
                );
              }
            }

            return fetchCategoryPostsBySlug({
              slug,
              page,
              excludeContent: shouldExcludeContent(),
              preFetch,
              useLazyPagination: true,
            });
          },
          true,
        ),
      );
    }

    let state = store.getState();
    const category = getCategoryBySlug(state, slug);
    const lang = getQueryLocale(state);

    if (lang && !category) {
      const translatedCategory = getCategoryByTranslationSlug(
        state,
        slug,
        lang,
      );

      if (translatedCategory) {
        return redirect(
          `/categories/${encodeURISlug(translatedCategory.slug)}`,
        );
      }
    }

    try {
      if (preFetchResult) {
        await store.dispatch(
          completeFetchCategoryPosts(
            {
              slug,
              page,
              pageSize: getPageSizeFromSettings(store.getState(), {
                section: SECTION_CATEGORY,
              }),
              excludeContent: shouldExcludeContent(),
            },
            preFetchResult,
          ),
        );
        store.dispatch(fetchProfileUrls());
      } else {
        await store.dispatch(
          fetchCategoryPostsBySlug({
            slug,
            page,
            excludeContent: shouldExcludeContent(),
            preFetch,
            useLazyPagination: true,
          }),
        );
      }

      state = store.getState();
      const category = getCategoryBySlug(state, slug);
      const showPagination = getShowPagination(state, SECTION_CATEGORY);
      const isValidPage = getIsValidPage(state, SECTION_CATEGORY);
      if (showPagination && !isValidPage) {
        redirect(ROUTE_404);
      }

      if (isSite(state)) {
        const appConfig = getAppConfig(state);
        const sectionUrl = getSectionUrl(state);
        const categorySEOTags = await generateCategorySEOTags({
          appConfig,
          sectionUrl,
          category,
          store,
          page,
          multilingual: wixCodeApi.window.multilingual,
        });
        wixCodeApi.seo.resetSEOTags();
        wixCodeApi.seo.renderSEOTags(categorySEOTags);
      }

      !isSSR(store.getState()) &&
        store.dispatch(pageOpened({ page: CATEGORY_PAGE, category }));
    } catch (error) {
      if (error.status === 404) {
        return redirect(ROUTE_404);
      }
      throw error;
    }
  };
